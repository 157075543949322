import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { PelaksanaanService } from "../../services/pelaksanaan/pelaksanaan.service";
import { AuthService } from "../../services/auth/auth.service";
import { PermasalahanService } from "../../services/permasalahan/permasalahan.service";
import { getMinutes } from "ngx-bootstrap/chronos/utils/date-getters";

@Component({
  selector: "stacked-bar-chart",
  templateUrl: "./stacked-bar-chart.component.html",
  styleUrls: ["./stacked-bar-chart.component.css"],
})
export class StackedBarChartComponent implements OnInit {
  data;
  loading: Boolean;
  @Input() service;
  @Input() url: Observable<any>;
  @Input() role;
  @Input() tahun;
  @Input() bulan;
  @Input() disableSelect;
  @Output() onChange = new EventEmitter<any>();
  @Output() onSelected = new EventEmitter<any>();
  @Output() typeChart = new EventEmitter<any>();
  yearNow;
  monthNow;
  colorScheme = {
    domain: [
      "#ef5350",
      "#5c6bc0",
      "#29b6f6",
      "#d4e157",
      "#ff7043",
      "#66bb6a",
      "#7e57c2",
      "#26a69a",
      "#8d6e63",
    ],
  };
  back = false;
  profile;
  isSelected: Boolean = false;

  constructor(
    private servicePelaksanaan: PelaksanaanService,
    private servicePermasalahan: PermasalahanService,
    private serviceAuth: AuthService
  ) {}

  ngOnInit() {
    this.getData();
  }

  onSelect(e) {
    if (!this.disableSelect) {
      if (!this.isSelected) {
        this.isSelected = true;
        this.typeChart.emit("kabkot");
        if (localStorage.getItem("profile") != null) {
          if (this.role == "KSB_PUSAT") {
            this.reload(
              this.servicePelaksanaan.getDataBarChartKabKot(
                e.kode,
                this.tahun || "",
                this.bulan || ""
              )
            );
          } else if (this.role == "FKUB_PUSAT") {
            this.onSelected.emit(e.kode);
          } else if (this.role == "KSB_PROV") {
            this.reload(
              this.servicePelaksanaan.getDataBarChartKecamatan(
                e.kode,
                this.tahun || "",
                this.bulan || ""
              )
            );
          }
        } else {
          this.onSelected.emit(e.kode);
        }
      } else {
        //kecamatan
        if (this.role == "KSB_PUSAT" && e.kode != null) {
          this.typeChart.emit("kecamatan");

          this.reload(
            this.servicePelaksanaan.getDataBarChartKecamatan(
              e.kode,
              this.tahun || "",
              this.bulan || ""
            )
          );
        }
      }

      this.back = true;
    }
  }

  onBack() {
    this.back = false;
    this.isSelected = false;
    this.typeChart.emit("province");
    this.getData();
  }

  getData() {
    this.url.subscribe(
      (resp) => {
        this.data = resp.data;
        this.onChange.emit(resp.filterTahun);
      },
      (error) => {}
    );
  }

  reload(url) {
    url.subscribe(
      (resp) => {
        this.data = resp.data;
        this.onChange.emit(resp.filterTahun);
      },
      (error) => {}
    );
  }
}
