import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import {
  environment,
  environment_dashboard,
  environment_document,
} from "../../../environments/environment";
import {
  DataResponse,
  SummaryResponse,
} from "../../models/response/DataResponse";
import {
  HandleError,
  HttpErrorHandlerService,
} from "../../handler/http-error-handler.service";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class PelaksanaanService {
  private prefixUrl = environment.server;
  private prefixUrlDashboard = environment_dashboard.server;
  private prefixUrlDocument = environment_document.server;
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService,
    public jwtHelper: JwtHelperService
  ) {
    this.handleError = httpErrorHandler.createHandleError("PelaksanaanService");
  }

  createDokumenPelaksanaan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/create-dokumen",
      dataRequest,
      httpOptions
    );
  }

  createDokumenLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/create-laporan-pelaksanaan",
      dataRequest,
      httpOptions
    );
  }

  deleteDokumen(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/delete-dokumen",
      dataRequest,
      httpOptions
    );
  }

  deleteLaporanPelaksanaan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/delete-laporan-pelaksanaan",
      dataRequest,
      httpOptions
    );
  }

  getDetailLaporanPelaksanaan(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl +
        "/laporanpelaksanaan/detail-laporan-pelaksanaan/" +
        dataRequest,
      httpOptions
    );
  }

  getDetailBerkas(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDocument + "/dokumen/detail/" + dataRequest,
      httpOptions
    );
  }

  getUraian(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/get-uraian-pelaksanaan",
      httpOptions
    );
  }

  verifDoc(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/dokumen/verifikasi",
      dataRequest,
      httpOptions
    );
  }

  verifReport(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/approve-laporan",
      dataRequest,
      httpOptions
    );
  }

  rejectReport(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/reject-laporan",
      dataRequest,
      httpOptions
    );
  }

  getMapProvince(type, tahun = null): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPelaksanaan/map-province-pelaksanaan?tipe=" +
        type +
        (tahun && tahun !== "" ? `&tahun=${tahun}` : ""),
      httpOptions
    );
  }

  getMapDistrict(kdProvince, type, tahun = null): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPelaksanaan/map-district-pelaksanaan?kodeProvinsi=" +
        kdProvince +
        "&tipe=" +
        type +
        (tahun && tahun !== "" ? `&tahun=${tahun}` : ""),
      httpOptions
    );
  }

  getDataBarChart(tahun, bulan): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPelaksanaan/chart-pelaksanaan-rad?tahun=" +
        tahun +
        "&bulan=" +
        bulan,
      httpOptions
    );
  }

  getDataBarChartKabKot(kodeProvinsi, tahun, bulan): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPelaksanaan/chart-pelaksanaan-rad-kabkot/" +
        kodeProvinsi +
        "?tahun=" +
        tahun +
        "&bulan=" +
        bulan,
      httpOptions
    );
  }

  getDataBarChartKecamatan(kodeKabkot, tahun, bulan): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "//dashboardPelaksanaan/chart-pelaksanaan-rad-kecamatan/" +
        kodeKabkot +
        "?tahun=" +
        tahun +
        "&bulan=" +
        bulan,
      httpOptions
    );
  }

  getLineChart(tahun): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPelaksanaan/line-chart-pelaksanaan?tahun=" +
        tahun,
      httpOptions
    );
  }

  getDetailDocument(id): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/dokumen/detail/" + id,
      httpOptions
    );
  }

  updateDocument(id, dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/dokumen/edit-dokumen/" + id,
      dataRequest,
      httpOptions
    );
  }

  updateDocumentLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/update-laporan-pelaksanaan/",
      dataRequest,
      httpOptions
    );
  }

  exportMonitoringProvinsi(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrlDocument + "/dokumen/pdfExport-monitoring-provinsi",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/export-monitoring-provinsi",
      dataRequest,
      httpOptions
    );
  }

  exportMonitoringKabkot(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrlDocument + "/dokumen/pdfExport-monitoring-kabkot",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/export-monitoring-kabkot",
      dataRequest,
      httpOptions
    );
  }

  exportMonitoringKecamatan(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrlDocument + "/dokumen/pdfExport-monitoring-kecamatan",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/export-monitoring-kecamatan",
      dataRequest,
      httpOptions
    );
  }

  exportLaporanMonitoringPusat(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrl + "/laporanpelaksanaan/pdfExport-monitoring-pusat",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/export-monitoring-pusat",
      dataRequest,
      httpOptions
    );
  }

  exportLaporanMonitoringKabkot(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrl + "/laporanpelaksanaan/pdfExport-monitoring-kabkot",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/export-monitoring-kabkot",
      dataRequest,
      httpOptions
    );
  }

  exportLaporanMonitoringKecamatan(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrl + "/laporanpelaksanaan/pdfExport-monitoring-kecamatan",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpelaksanaan/export-monitoring-kecamatan",
      dataRequest,
      httpOptions
    );
  }

  countVerifikasi(tipeVerifikasi): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/verifikasi/count/" + tipeVerifikasi,
      null,
      httpOptions
    );
  }

  summaryMonitoring(level, dataRequest): Observable<SummaryResponse> {
    return this.http.post<SummaryResponse>(
      this.prefixUrlDocument + "/dokumen/monitoring-summary/" + level,
      dataRequest,
      httpOptions
    );
  }

  countPelaksanaan(): Observable<SummaryResponse> {
    return this.http.get<SummaryResponse>(
      `${this.prefixUrlDocument}/dokumen/count/data-table`,
      httpOptions
    );
  }

  countPelaksanaanRAD(): Observable<SummaryResponse> {
    return this.http.get<SummaryResponse>(
      `${this.prefixUrl}/laporanpelaksanaan/count/data-table`,
      httpOptions
    );
  }

  exportKelengkapanBerkas(
    dataRequest,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrlDocument + "/dokumen/dasboardPdf-monitoring-provinsi",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrlDocument + "/dokumen/dasboardExcel-monitoring-provinsi",
      dataRequest,
      httpOptions
    );
  }
}
