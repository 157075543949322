import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DASHBOARD_LINE } from "../../models/Dummy";
import { Observable } from "rxjs";

@Component({
  selector: "line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent implements OnInit {
  data;
  @Input() url: Observable<any>;
  @Input() role;
  @Input() chartType;
  @Output() onChange = new EventEmitter<any>();

  color = {
    domain: ["#214c9b"],
  };

  constructor() {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.url.subscribe(
      (resp) => {
        this.data = resp.data;
        this.onChange.emit(resp.filterTahun);
      },
      (error) => {}
    );
  }

  reload(url) {
    url.subscribe(
      (resp) => {
        this.data = resp.data;
      },
      (error) => {}
    );
  }
}
