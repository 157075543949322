import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IframeService } from '../../services/iframe/iframe.service';
import { LineChartComponent } from '../../shared/line-chart/line-chart.component';
import { HttpHeaders, HttpRequest } from "@angular/common/http";
@Component({
  selector: 'app-line-chart-page',
  templateUrl: './line-chart-page.component.html',
  styleUrls: ['./line-chart-page.component.css']
})
export class LineChartPageComponent implements OnInit {

  @ViewChild('lineChart')
  lineChart: LineChartComponent

  params
  kdProv = null
  queryParam
  role
  urlLine
  dateNow
  yearNow
  arrayTahun
  selectedYear
  token
  title

  constructor(
    private service: IframeService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    var splitQueryParam = this.router.url.split('?')
    var splitUrl = splitQueryParam[0].split('/')
    this.dateNow = new Date()
    this.yearNow = this.dateNow.getFullYear()
    this.params = this.route.snapshot.params.role
    this.role = splitUrl[2]
    this.route.queryParamMap.subscribe((params) => {
      this.queryParam = params
    })
    this.token = this.queryParam.params.token
    if (this.role == 'KSB_PUSAT') {
      this.title = 'Jumlah Pelaksanaan RAD Seluruh Indonesia'
      this.urlLine = this.service.getLineKSBChartPusat(this.role, this.yearNow, this.token)
    } else if (this.role == 'KSB_PROV') {
      this.title = 'Jumlah Pelaksanaan RAD Per-Kabupaten/Kota'
      this.kdProv = this.queryParam.params.kodeProv
      this.urlLine = this.service.getLineKSBChartNonPusat(this.role, this.yearNow, this.kdProv, this.token)
    } else if(this.role == 'FKUB_PUSAT'){
      this.title = 'Jumlah Permasalahan KUB/PK Per Bulan'
      this.urlLine = this.service.getLineFKUBChartPusat(this.role, this.yearNow, this.token)
    } else {
      this.title = 'Jumlah Permasalahan KUB/PK Per Bulan'
      this.kdProv = this.queryParam.params.kodeProv
      this.urlLine = this.service.getLineFKUBChartNonPusat(this.role, this.yearNow, this.kdProv, this.token)
    }

  }

  arrayTahunChange(e) {
    this.arrayTahun = e
  }

  onProvinceChange(e){
    if(this.role == 'KSB_PROV'){
      this.urlLine = this.service.getLineKSBChartNonPusat(this.role, this.selectedYear == undefined? this.yearNow : this.selectedYear, e, this.token)
      this.lineChart.reload(this.urlLine)
    } else if(this.role == 'FKUB_PROV'){
      this.urlLine = this.service.getLineFKUBChartNonPusat(this.role, this.selectedYear == undefined? this.yearNow : this.selectedYear, e, this.token)
      this.lineChart.reload(this.urlLine)
    }
  }

  onChangeYear(e) {
    this.selectedYear = e
    if (this.role == 'KSB_PUSAT') {
      this.urlLine = this.service.getLineKSBChartPusat(this.role, this.selectedYear, this.token)
      this.lineChart.reload(this.urlLine)
    } else if (this.role == 'KSB_PROV') {
      this.urlLine = this.service.getLineKSBChartNonPusat(this.role, this.selectedYear, this.kdProv, this.token)
      this.lineChart.reload(this.urlLine)
    } else if(this.role == 'FKUB_PUSAT'){
      this.urlLine = this.service.getLineFKUBChartPusat(this.role, this.selectedYear, this.token)
      this.lineChart.reload(this.urlLine)
    } else {
      this.urlLine = this.service.getLineFKUBChartNonPusat(this.role, this.selectedYear, this.kdProv, this.token)
      this.lineChart.reload(this.urlLine)
    }
  }

}
