import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "../app/views/login/login.component";
import { RegisterComponent } from "../app/views/register/register.component";
import { AuthGuardService } from "./services/auth/auth-guard.service";
import { LineChartPageComponent } from "./iframe/line-chart-page/line-chart-page.component";
import { StackedChartPageComponent } from "./iframe/stacked-chart-page/stacked-chart-page.component";

// Import Containers
import { DefaultLayoutComponent } from "./containers";
import {
  AuthenticatePolpumComponent,
  AuthenticateKominfoComponent,
  CheckPolpumComponent,
} from "../app//views/authenticate/authenticate.component";
import { AuthParentGuardService } from "./services/auth/auth-parent-guard.service";
import { NotFoundComponent } from "./views/404/404.component";
import { TermConditionComponent } from "./views/term-condition/term-condition.component";
import { PrivacyPolicyComponent } from "./views/privacy-policy/privacy-policy.component";

export const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "authenticate-polpum",
    component: AuthenticatePolpumComponent,
  },
  {
    path: "check-polpum",
    component: CheckPolpumComponent,
  },
  {
    path: "authenticate-kominfo",
    component: AuthenticateKominfoComponent,
  },
  {
    path: "confirm-registration",
    component: RegisterComponent,
  },
  {
    path: "line-chart/:role?kdProv=",
    component: LineChartPageComponent,
  },
  {
    path: "stacked-bar-chart/:role",
    component: StackedChartPageComponent,
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [AuthParentGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: "permasalahan",
        loadChildren: () =>
          import("./views/permasalahan/permasalahan.module").then(
            (m) => m.PermasalahanModule
          ),
      },
      {
        path: "pelaksanaan",
        loadChildren: () =>
          import("./views/pelaksanaan/pelaksanaan.module").then(
            (m) => m.PelaksanaanModule
          ),
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./views/admin/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "term-condition",
    component: TermConditionComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  { path: "404", component: NotFoundComponent },
  {
    path: "**",
    redirectTo: "/404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
