import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { environment, environment_account, environment_user } from "../../../environments/environment";
import { DataResponse } from "../../models/response/DataResponse";
import {
  HandleError,
  HttpErrorHandlerService,
} from "../../handler/http-error-handler.service";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

const httpOptionsToken = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }),
};
@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  private prefixUrlAccount = environment_account.server
  private prefixUrlUser = environment_user.server
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService,
    public jwtHelper: JwtHelperService
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      "UserManagementService"
    );
  }

  updateProfile(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlAccount + "/user/edit-profile", dataRequest, httpOptionsToken
    );
  }

  resetPassword(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlAccount + "/user/reset-password", dataRequest, httpOptionsToken
    );
  }

  adduser(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlUser + "/user/add-user", dataRequest, httpOptionsToken
    );
  }

  accountRegister(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlAccount + "/account/register", dataRequest, httpOptionsToken
    );
  }

  deleteUser(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlUser + "/user/delete-user", dataRequest, httpOptionsToken
    );
  }

  editUser(id, dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlUser + "/user/edit-user/" + id, dataRequest, httpOptionsToken
    );
  }

  getCountUser(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlUser + "/user/counting-user", httpOptionsToken
    );
  }

  getMapProvince(type, kdProvince): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlUser + "/user/map-user/" + type + '/' + kdProvince, httpOptions
    );
  }

}
