import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterProvinsiComponent } from './filter-provinsi.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        FilterProvinsiComponent
    ],
    imports: [
        CommonModule,
        NgxSelectModule,
        NgxLoadingModule,
        FontAwesomeModule,
        FormsModule
    ],
    entryComponents: [
        FilterProvinsiComponent,
    ],
    exports: [
        FilterProvinsiComponent
    ]
})
export class FilterProvinsiModule { }