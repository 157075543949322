import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LineChartPageComponent } from './line-chart-page.component';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LineChartModule } from '../../shared/line-chart/line-chart.module';
import { LineChartComponent } from '../../shared/line-chart/line-chart.component';
import { FilterTahunComponent } from '../../shared/filter-tahun/filter-tahun.component';
import { FilterTahunModule } from '../../shared/filter-tahun/filter-tahun.module';
import { FilterProvinsiComponent } from '../../shared/filter-provinsi/filter-provinsi.component';
import { FilterProvinsiModule } from '../../shared/filter-provinsi/filter-provinsi.module';

@NgModule({
    imports: [
        CommonModule,
        NgxSelectModule,
        NgxLoadingModule,
        FontAwesomeModule,
        FormsModule,
        NgxChartsModule,
        LineChartModule,
        FilterTahunModule,
        FilterProvinsiModule
    ],
    declarations: [
        LineChartPageComponent,
    ],
    entryComponents: [
        LineChartPageComponent,
        LineChartComponent,
        FilterTahunComponent,
        FilterProvinsiComponent
    ],
    exports: [
        LineChartPageComponent
    ]
})
export class IframeLineChartPageModule { }