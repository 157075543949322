import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterTahunComponent } from './filter-tahun.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FilterTahunComponent
  ],
  imports: [
    CommonModule,
    NgxSelectModule,
    NgxLoadingModule,
    FontAwesomeModule,
    FormsModule
  ],
  entryComponents:[
    FilterTahunComponent,
  ],
  exports:[
    FilterTahunComponent
  ]
})
export class FilterTahunModule { }