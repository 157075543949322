import { Component } from "@angular/core";

@Component({
  selector: "app-term-condition",
  templateUrl: "./term-condition.component.html",
  styleUrls: ["./term-condition.component.css"],
})
export class TermConditionComponent {
  constructor() {}
}
