import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IframeService } from '../../services/iframe/iframe.service';
import { StackedBarChartComponent } from '../../shared/stacked-bar-chart/stacked-bar-chart.component';
import { FilterBulanComponent } from '../../shared/filter-bulan/filter-bulan.component';

@Component({
  selector: 'app-stacked-chart-page',
  templateUrl: './stacked-chart-page.component.html',
  styleUrls: ['./stacked-chart-page.component.css']
})
export class StackedChartPageComponent implements OnInit {

  @ViewChild('stackedBarChart')
  stackedBarChart: StackedBarChartComponent

  @ViewChild('filterBulan')
  filterBulan: FilterBulanComponent

  role
  urlStackedBar
  dateNow
  yearNow
  monthNow
  arrayTahun
  queryParam
  token
  selectedYear
  selectedMonth
  typeChart = 'province'
  kdKabKot
  title

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: IframeService
  ) { }

  ngOnInit() {
    var splitQueryParam = this.router.url.split('?')
    var splitUrl = splitQueryParam[0].split('/')
    this.dateNow = new Date()
    this.yearNow = this.dateNow.getFullYear()
    this.monthNow = this.dateNow.getMonth() + 1
    this.role = splitUrl[2]
    this.route.queryParamMap.subscribe((params) => {
      this.queryParam = params
    })
    this.token = this.queryParam.params.token
    if (this.role === 'KSB_PUSAT') {
      this.title = 'Jumlah Laporan Pelaksanaan RAD Per Wilayah'
      this.urlStackedBar = this.service.getDataKSBBarChart('', '', this.token)
    } else {
      this.title = 'Jumlah Permasalahan KUB / PK'
      this.urlStackedBar = this.service.getDataFKUBBarChartPusat(this.role, '', '', this.token)
    }
  }

  arrayTahunChange(e) {
    this.arrayTahun = e
  }

  onTypeChart(e) {
    this.typeChart = e
    if (this.typeChart == 'province') {
      this.selectedYear = null
      this.arrayTahun = []
      this.filterBulan.reset()
    }
  }

  onChangeYear(e) {
    this.selectedYear = e
    if (this.role == 'KSB_PUSAT') {
      if (this.typeChart == 'province') {
        this.stackedBarChart.reload(this.service.getDataKSBBarChart(this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
      } else {
        this.stackedBarChart.reload(this.service.getDataKSBBarChartKabKot(this.kdKabKot, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
      }
    } else {
      if (this.typeChart == 'province') {
        this.stackedBarChart.reload(this.service.getDataFKUBBarChartPusat(this.role, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
      } else {
        this.stackedBarChart.reload(this.service.getDataFKUBBarChartNonPusat('FKUB_PROV', this.kdKabKot, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
      }
    }
  }

  onChangeMonth(e) {
    this.selectedMonth = e
    if (this.role == 'KSB_PUSAT') {
      if (this.typeChart == 'province') {
        this.stackedBarChart.reload(this.service.getDataKSBBarChart(this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth == null ? '' : this.selectedMonth, this.token))
      } else {
        this.stackedBarChart.reload(this.service.getDataKSBBarChartKabKot(this.kdKabKot, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth == null ? '' : this.selectedMonth, this.token))
      }
    } else {
      if (this.typeChart == 'province') {
        this.stackedBarChart.reload(this.service.getDataFKUBBarChartPusat(this.role, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
      } else {
        this.stackedBarChart.reload(this.service.getDataFKUBBarChartNonPusat('FKUB_PROV', this.kdKabKot, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
      }
    }
  }

  isSelect(e) {
    this.kdKabKot = e
    console.log(this.kdKabKot)
    if (this.role == 'KSB_PUSAT') {
      this.stackedBarChart.reload(this.service.getDataKSBBarChartKabKot(this.kdKabKot, this.selectedYear != null ? this.selectedYear : '', this.selectedMonth != null ? this.selectedMonth : '', this.token))
    } else {
      this.stackedBarChart.reload(this.service.getDataFKUBBarChartNonPusat('FKUB_PROV', this.kdKabKot, this.selectedYear == null ? '' : this.selectedYear, this.selectedMonth != null ? this.selectedMonth : '', this.token))
    }
  }

}
