import { environment } from "./../../../environments/environment";
import { Component } from "@angular/core";
import { NavData, NavMenu } from "../../_nav";
import { AuthService } from "../../services/auth/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { PelaksanaanService } from "../../services/pelaksanaan/pelaksanaan.service";
import { EventBus } from "../../event/event-bus";
import { PermasalahanService } from "../../services/permasalahan/permasalahan.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RoleMenus } from "../../utilities/role-menus";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  minimized = false;
  public navItems: NavData[] = [];
  public navMenu: NavMenu = new NavMenu();
  role;
  type;
  username;
  profileSrc;
  private unsubscribe: Subject<void> = new Subject();
  currentYear: number = new Date().getFullYear();

  constructor(
    public auth: AuthService,
    private router: Router,
    private servicePelaksanaan: PelaksanaanService,
    private servicePermasalahan: PermasalahanService,
    private http: HttpClient,
    private roleMenus: RoleMenus
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getCount();
        this.getMenuNotification();
        this.getPelaksanaanCount();
        this.getPembinaanCount();
        this.getSKCount();
        this.getAnggaranCount();
      }
    });
  }

  ngOnInit() {
    this.navItems = this.navMenu.baseMenu();
    this.role = this.auth.profileUser().roles[0];
    this.type = this.role.substr(0, 3);
    this.username = this.auth.profileUser().fullname;

    this.listMenu();
    this.registerEvent();

    const profile = JSON.parse(localStorage.getItem("profile"));
    this.profileSrc = profile.urlFoto;
  }

  registerEvent() {
    EventBus.getInstance().register("NOTIFY", () => {
      this.getCount();
      this.getMenuNotification();
      this.getPelaksanaanCount();
      this.getPembinaanCount();
      this.getSKCount();
      this.getAnggaranCount();
    });
  }

  toggleMinimize(e) {
    this.minimized = e;
  }

  logout() {
    this.auth.eraseSessionData();
    // window.location.href = environment.logoutUrl;
    // this.auth.logoutPolpum().subscribe()
    window.location.reload();
  }

  editProfile() {
    this.router.navigate(["admin/edit-profile"]);
  }

  gantiPassword() {
    this.router.navigate(["admin/ganti-password"]);
  }

  getCount() {
    const myRole = this.auth.profileUser().roles[0];

    if (
      myRole !== undefined &&
      myRole !== "KSB_PROV" &&
      myRole !== "KSB_KABKOT"
    ) {
      this.servicePelaksanaan.countVerifikasi("verifikasi-provinsi").subscribe(
        (resp) => {
          if (resp && resp["recordsTotal"] != null) {
            const value = resp["recordsTotal"];
            const tempMenu = [...this.navItems].map((it: any) => {
              if (it.children) {
                const tempChilds: any[] = [...it.children].map(
                  (itChild: any) => {
                    const url = itChild.url;

                    if (url === "pelaksanaan/verifikasi/provinsi") {
                      return {
                        ...itChild,
                        ...(value && {
                          badge: {
                            text: value,
                            variant: "btn btn-danger",
                          },
                        }),
                        ...(!value && {
                          badge: undefined,
                        }),
                      };
                    }

                    return itChild;
                  }
                );

                return {
                  ...it,
                  children: tempChilds,
                };
              }

              return it;
            });

            this.navItems = [...tempMenu];
            this.getTotalKabkot(value);
          }
        },
        (error) => {
          console.log("RFM1", error);
        }
      );
    } else if (myRole === "KSB_PROV") {
      this.getTotalKabkot(0);
    }
  }

  getMenuNotification() {
    this.servicePermasalahan.countMenuNotification().subscribe(
      (resp) => {
        const tempMenu = [...this.navItems].map((it: any) => {
          if (it.url) {
            const getCount = this.getNotifCount(it.url, resp);

            return {
              ...it,
              ...(getCount && {
                badge: {
                  text: getCount,
                  variant: "btn btn-danger",
                },
              }),
              ...(!getCount && {
                badge: undefined,
              }),
            };
          }

          return it;
        });

        this.navItems = [...tempMenu];
      },
      (error) => {
        console.log("RFM1", error);
      }
    );
  }

  getPelaksanaanCount() {
    const role = this.auth.profileUser().roles[0];

    if (role && (role === "KSB_PROV" || role === "KSB_KABKOT")) {
      this.servicePelaksanaan.countPelaksanaan().subscribe(
        (resp) => {
          this.servicePelaksanaan.countPelaksanaanRAD().subscribe(
            (resp2) => {
              const tempMenu = [...this.navItems].map((it: any) => {
                if (it.children) {
                  const childs = [...it.children].map((itChild: any) => {
                    const getCount = this.getNotifPelaksanaanCount(
                      itChild.url,
                      { ...resp, ...resp2 },
                      role
                    );

                    return {
                      ...itChild,
                      ...(getCount && {
                        badge: {
                          text: getCount,
                          variant: "btn btn-danger",
                        },
                      }),
                      ...(!getCount && {
                        badge: undefined,
                      }),
                    };
                  });

                  const countParent = childs.reduce((acc: any, curr: any) => {
                    if (typeof acc === "object") {
                      return Number(curr.badge?.text) || 0;
                    }

                    return acc + (Number(curr.badge?.text) || 0);
                  }, 0);

                  return {
                    ...it,
                    ...(it.url === "pelaksanaan/daftar" &&
                      countParent && {
                        badge: {
                          text: countParent,
                          variant: "btn btn-danger",
                        },
                      }),
                    ...(it.url === "pelaksanaan/daftar" &&
                      !countParent && {
                        badge: undefined,
                      }),
                    children: [...childs],
                  };
                }

                return it;
              });

              this.navItems = [...tempMenu];
            },
            (error) => {
              console.log("RFM1", error);
            }
          );
        },
        (error) => {
          console.log("RFM1", error);
        }
      );
    }
  }

  getNotifCount(url: string, dataNotif: Record<string, any>) {
    const config: Record<string, string> = {
      pusatProv: "/permasalahan/daftar-permasalahan-provinsi",
      pusatKabkot: "/permasalahan/daftar-permasalahan-kabkot",
      inputLaporan: "/permasalahan/daftar-permasalahan",
      identifikasi: "/permasalahan/penelitian-klarifikasi-identifikasi",
      verifikasi: "/permasalahan/verifikasi-koordinasi",
      penanganan: "/permasalahan/penanganan-masalah",
      draftLaporan: "/permasalahan/laporan-hasil",
      laporanFinal: "/permasalahan/permasalahan-selesai",
      finalAccepted: "/permasalahan/laporan-permasalahan",
    };

    const findKey = Object.keys(config).find(
      (it: string) => config[it] === url
    );

    if (findKey) {
      return dataNotif[findKey];
    }

    return null;
  }

  getNotifPelaksanaanCount(
    url: string,
    dataNotif: Record<string, any>,
    role: string
  ) {
    if (role === "KSB_PROV" && url === "pelaksanaan/daftar/timdu-rad-perda") {
      return dataNotif.countProvinsi;
    } else if (
      role === "KSB_KABKOT" &&
      url === "pelaksanaan/daftar/timdu-rad-perda"
    ) {
      return dataNotif.countKota;
    } else if (url === "pelaksanaan/daftar/timdu-rad-kecamatan") {
      return dataNotif.countKecamatan;
    } else if (url === "pelaksanaan/daftar/laporan-rad") {
      return dataNotif.countData;
    }

    return null;
  }

  getTotalKabkot(total) {
    this.servicePelaksanaan.countVerifikasi("verifikasi-kabkot").subscribe(
      (resp) => {
        if (resp && resp["recordsTotal"] != null) {
          const totalKabkot = resp["recordsTotal"];

          const tempMenu = [...this.navItems].map((it: any) => {
            if (it.children) {
              const tempParent = { ...it };

              if (it.url === "pelaksanaan/verifikasi") {
                const summary = totalKabkot + total;

                if (summary) {
                  tempParent.badge = {
                    text: summary,
                    variant: "btn btn-danger",
                  };
                } else {
                  tempParent.badge = undefined;
                }
              }

              const tempChilds: any[] = [...it.children].map((itChild: any) => {
                const url = itChild.url;

                if (url === "pelaksanaan/verifikasi/kabkot") {
                  return {
                    ...itChild,
                    ...(totalKabkot && {
                      badge: {
                        text: totalKabkot,
                        variant: "btn btn-danger",
                      },
                    }),
                    ...(!totalKabkot && {
                      badge: undefined,
                    }),
                  };
                }

                return itChild;
              });

              return {
                ...tempParent,
                children: tempChilds,
              };
            }

            return it;
          });

          const summary = totalKabkot + total;
          this.navItems = [...tempMenu];
          this.getTotalKecamatan(summary);
        }
      },
      (error) => {
        console.log("RFM1", error);
      }
    );
  }

  getTotalKecamatan(total) {
    this.servicePelaksanaan.countVerifikasi("verifikasi-kecamatan").subscribe(
      (resp) => {
        if (resp && resp["recordsTotal"] != null) {
          const totalKecamatan = resp["recordsTotal"];
          const tempMenu = [...this.navItems].map((it: any) => {
            if (it.children) {
              const tempParent = { ...it };

              if (it.url === "pelaksanaan/verifikasi") {
                const summary = totalKecamatan + total;

                if (summary) {
                  tempParent.badge = {
                    text: summary,
                    variant: "btn btn-danger",
                  };
                } else {
                  tempParent.badge = undefined;
                }
              }

              const tempChilds: any[] = [...it.children].map((itChild: any) => {
                const url = itChild.url;

                if (url === "pelaksanaan/verifikasi/kecamatan") {
                  return {
                    ...itChild,
                    ...(totalKecamatan && {
                      badge: {
                        text: totalKecamatan,
                        variant: "btn btn-danger",
                      },
                    }),
                    ...(!totalKecamatan && {
                      badge: undefined,
                    }),
                  };
                }

                return itChild;
              });

              return {
                ...tempParent,
                children: tempChilds,
              };
            }

            return it;
          });

          this.navItems = [...tempMenu];
          const summary = totalKecamatan + total;
          this.getTotalLaporanRAD(summary);
        }
      },
      (error) => {
        console.log("RFM1", error);
      }
    );
  }

  getTotalLaporanRAD(total) {
    this.http
      .post<any>(
        environment.server + "/laporanpelaksanaan/datatable-verifikasi",
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          withCredentials: false,
        }
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp) => {
        if (resp && resp.recordsTotal) {
          const totalLaporanRAD = resp.recordsTotal;
          const tempMenu = [...this.navItems].map((it: any) => {
            if (it.children) {
              const tempParent = { ...it };

              if (it.url === "pelaksanaan/verifikasi") {
                const summary = totalLaporanRAD + total;

                if (summary) {
                  tempParent.badge = {
                    text: summary,
                    variant: "btn btn-danger",
                  };
                } else {
                  tempParent.badge = undefined;
                }
              }

              const tempChilds: any[] = [...it.children].map((itChild: any) => {
                const url = itChild.url;

                if (url === "pelaksanaan/verifikasi/laporan") {
                  return {
                    ...itChild,
                    ...(totalLaporanRAD && {
                      badge: {
                        text: totalLaporanRAD,
                        variant: "btn btn-danger",
                      },
                    }),
                    ...(!totalLaporanRAD && {
                      badge: undefined,
                    }),
                  };
                }

                return itChild;
              });

              return {
                ...tempParent,
                children: tempChilds,
              };
            }

            return it;
          });

          this.navItems = [...tempMenu];
        }
      });
  }

  listMenu() {
    this.navItems = [...this.roleMenus.getRoleMenus(this.role, this.type)];
  }

  getPembinaanCount() {
    const role = this.auth.profileUser().roles[0];

    if (role && role === "FKUB_PUSAT") {
      this.servicePermasalahan.countMenuPembinaan().subscribe(
        (resp: any) => {
          const tempMenu = [...this.navItems].map((it: any) => {
            if (it.url?.includes("laporan-pembinaan")) {
              return {
                ...it,
                ...(resp?.countPembinaan && {
                  badge: {
                    text: resp.countPembinaan,
                    variant: "btn btn-danger",
                  },
                }),
                ...(!resp?.countPembinaan && {
                  badge: undefined,
                }),
              };
            }
            return it;
          });
          this.navItems = [...tempMenu];
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getSKCount() {
    const role = this.auth.profileUser().roles[0];

    if (role && role === "FKUB_PUSAT") {
      this.servicePermasalahan.countMenuDokumenSK().subscribe(
        (resp: any) => {
          const tempMenu = [...this.navItems].map((it: any) => {
            if (it.url?.includes("dokumen-sk-provinsi")) {
              return {
                ...it,
                ...(resp?.countProv && {
                  badge: {
                    text: resp.countProv,
                    variant: "btn btn-danger",
                  },
                }),
                ...(!resp?.countProv && {
                  badge: undefined,
                }),
              };
            } else if (it.url?.includes("dokumen-sk-kabkot")) {
              return {
                ...it,
                ...(resp?.countKabkot && {
                  badge: {
                    text: resp.countKabkot,
                    variant: "btn btn-danger",
                  },
                }),
                ...(!resp?.countKabkot && {
                  badge: undefined,
                }),
              };
            }
            return it;
          });
          this.navItems = [...tempMenu];
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getAnggaranCount() {
    const role = this.auth.profileUser().roles[0];

    if (role && role === "FKUB_PUSAT") {
      this.servicePermasalahan.countMenuAnggaran().subscribe(
        (resp: any) => {
          const tempMenu = [...this.navItems].map((it: any) => {
            if (it.url?.includes("daftar-anggaran-provinsi")) {
              return {
                ...it,
                ...(resp?.countProv && {
                  badge: {
                    text: resp.countProv,
                    variant: "btn btn-danger",
                  },
                }),
                ...(!resp?.countProv && {
                  badge: undefined,
                }),
              };
            } else if (it.url?.includes("daftar-anggaran-kabkot")) {
              return {
                ...it,
                ...(resp?.countKabkot && {
                  badge: {
                    text: resp.countKabkot,
                    variant: "btn btn-danger",
                  },
                }),
                ...(!resp?.countKabkot && {
                  badge: undefined,
                }),
              };
            }
            return it;
          });
          this.navItems = [...tempMenu];
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }
}
