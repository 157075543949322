import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { environment_dashboard } from "../../../environments/environment";
import { DataResponse } from "../../models/response/DataResponse";
import { HandleError, HttpErrorHandlerService } from "../../handler/http-error-handler.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class IframeService {

  private prefixUrlDashboard = environment_dashboard.server;
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService,
    public jwtHelper: JwtHelperService
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      "IframeService"
    );
  }

  getLineKSBChartPusat(role, tahun, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPelaksanaan/line-chart-pelaksanaan?loginAs=" + role + '&tahun=' + tahun,
      httpOptions
    );
  }

  getLineKSBChartNonPusat(role, tahun, kdProv, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPelaksanaan/line-chart-pelaksanaan?loginAs=" + role + '&kodeProv=' + kdProv + '&tahun=' + tahun,
      httpOptions
    );
  }

  getDataKSBBarChart(tahun, bulan, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPelaksanaan/chart-pelaksanaan-rad?tahun=" + tahun + "&bulan=" + bulan, httpOptions
    );
  }

  getDataKSBBarChartKabKot(kodeProvinsi, tahun, bulan, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPelaksanaan/chart-pelaksanaan-rad-kabkot/" + kodeProvinsi + "?tahun=" + tahun + "&bulan=" + bulan, httpOptions
    );
  }

  getLineFKUBChartPusat(role, tahun, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPermasalahan/line-chart-permasalahan?loginAs=" + role + '&tahun=' + tahun,
      httpOptions
    );
  }

  getLineFKUBChartNonPusat(role, tahun, kdProv, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPermasalahan/line-chart-permasalahan?loginAs=" + role + '&kodeProv=' + kdProv + '&tahun=' + tahun,
      httpOptions
    );
  }

  getDataFKUBBarChartPusat(role, tahun, bulan, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPermasalahan/stacked-chart-permasalahan?loginAs=" + role + "&tahun=" + tahun + '&bulan=' + bulan, httpOptions
    );
  }

  getDataFKUBBarChartNonPusat(role, kdProv, tahun, bulan, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPermasalahan/stacked-chart-permasalahan?loginAs=" + role + "&kodeProv=" + kdProv + "&tahun=" + tahun + '&bulan=' + bulan, httpOptions
    );
  }

  getDataKSBMapChartPusat(type, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPelaksanaan/map-province-pelaksanaan?tipe=" + type, httpOptions
    );
  }

  getDataKSBMapChartNonPusat(type, kdProvince, token): Observable<DataResponse> {
    httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + token)
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard + "/dashboardPelaksanaan/map-district-pelaksanaan?kodeProvinsi=" + kdProvince + '&tipe=' + type, httpOptions
    );
  }

}
