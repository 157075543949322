export class LoginModel {
  captcha: string | null;
  username: string = "";
  password: string = "";

  static defaultData() {
    return {
      captcha: null,
      username: "",
      password: "",
    };
  }
}
