import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LineChartComponent } from './line-chart.component';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    declarations: [
        LineChartComponent
    ],
    imports: [
        CommonModule,
        NgxSelectModule,
        NgxLoadingModule,
        FontAwesomeModule,
        FormsModule,
        NgxChartsModule
    ],
    entryComponents: [
        LineChartComponent,
    ],
    exports: [
        LineChartComponent
    ]
})
export class LineChartModule { }