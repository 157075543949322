import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: "app-404",
  templateUrl: "./404.component.html",
  styleUrls: ["./404.component.css"],
})
export class NotFoundComponent implements OnInit {
  constructor(public auth: AuthService, private router: Router) {}

  ngOnInit() {}

  goHome() {
    const role = this.auth.profileUser()?.roles[0];

    if (role) {
      const type = role?.substr(0, 3);

      if (role == "SA") {
        this.router.navigateByUrl("admin/dashboard");
      } else {
        if (type == "FKU") {
          this.router.navigateByUrl("permasalahan/dashboard");
        } else {
          this.router.navigateByUrl("pelaksanaan/dashboard");
        }
      }
    } else {
      this.router.navigateByUrl("");
    }
  }
}
