import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LoginModel } from "../../models/response/Login";
import { AuthService } from "../../services/auth/auth.service";
import { SweetAlert } from "../../utilities/sweetalert";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { environment } from "../../../environments/environment";
import { RecaptchaComponent } from "ng-recaptcha";

type FormData = {
  username: string;
  password: string;
  recaptcha: string;
};

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @ViewChild("captchaRef") public captchaRef: RecaptchaComponent;

  data: LoginModel;
  showPassword = false;
  loading = false;
  validNotRobot = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  type;
  isProduction: boolean = environment.production;
  siteKey: string = environment.recaptchaSiteKey;
  captchaToken: string | null = null;

  constructor(
    private router: Router,
    private service: AuthService,
    private sweetAlert: SweetAlert
  ) {}

  ngOnInit() {
    this.data = LoginModel.defaultData();
  }

  toggleInput() {
    this.showPassword = !this.showPassword;
  }

  loginKominfo() {
    window.location.href = environment.authUrlKominfo;
  }

  loginPolpum() {
    window.location.href = environment.authUrlPolpum;
  }

  onSubmit(formLogin) {
    this.loading = true;
    this.service.login({ ...this.data, captcha: this.captchaToken }).subscribe(
      (resp) => {
        this.loading = false;

        this.type = resp.profile.roles[0].substr(0, 3);
        if (resp.profile.roles[0] == "SA") {
          this.router.navigateByUrl("admin/dashboard");
        } else {
          if (this.type == "FKU") {
            this.router.navigateByUrl("permasalahan/dashboard");
          } else {
            this.router.navigateByUrl("pelaksanaan/dashboard");
          }
        }
      },
      (error) => {
        this.loading = false;
        this.sweetAlert.showError("Username/Password salah");
        this.data.password = "";
        this.captchaRef.reset();
      }
    );
  }

  captchaResolved(event: any) {
    this.captchaToken = event;
  }

  toCondition() {
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree(["term-condition"])
    );
    window.open(url, "_blank");
  }

  toPrivacy() {
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree(["privacy-policy"])
    );
    window.open(url, "_blank");
  }
}
