import { Component, OnInit, Output } from "@angular/core";
import { ProfileModel } from "../../models/Profile";
import { NgForm } from "@angular/forms";
import { UserManagementService } from "../../services/user-management/user-management.service";
import { SweetAlert } from "../../utilities/sweetalert";
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  profile: ProfileModel;
  title: string;
  loading = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  showPassword = false;
  showConfirmPassword = false;

  togglePasswordInput() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordInput() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  constructor(
    public service: UserManagementService,
    private sweetAlert: SweetAlert,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.profile = ProfileModel.defaultData();
  }

  onSubmit(form: NgForm) {
    this.sweetAlert.showConfirm('Konfirmasi', 'Simpan?').then((result) => {
      if (result.value) {
        var data = {
          nip: form.value.nip,
          fullname: form.value.fullname,
          email: form.value.email,
          noKTP: form.value.noKTP,
          jabatan: form.value.jabatan,
          phoneNumber: form.value.phoneNumber,
          password: form.value.password,
          passwordConfirmation: form.value.confirmPassword
        };
        if (form.value.password == form.value.confirmPassword) {
          this.loading = true
          this.service.accountRegister(data).subscribe(
            (resp) => {
              this.loading = false;
              this.sweetAlert.showSuccess("Data Berhasil Disimpan. Silakan Login!");
              this.router.navigateByUrl('login');
            },
            (error) => {
              if (error.error.code == 1) {
                this.sweetAlert.showError('Username/Email tidak dapat digunakan')
              } else if(error.error.code == 0) {
                var errormap = error.error.errors.map(x => {
                  if (x.code == 'PasswordRequiresNonAlphanumeric') {
                    return 'Kata sandi harus memiliki setidaknya satu karakter non alfanumerik'
                  } else if (x.code == 'PasswordRequiresUpper') {
                    return "Kata sandi harus memiliki setidaknya satu huruf besar ('A'-'Z')"
                  } else if (x.code == "PasswordTooShort") {
                    return "Kata sandi harus minimal 6 karakter"
                  } else if (x.code == 'PasswordRequiresDigit') {
                    return "Kata sandi harus memiliki setidaknya satu digit ('0'-'9')"
                  }
                })
                this.sweetAlert.showError(errormap.join(', '));
              }
            }
          );
        } else {
          this.sweetAlert.showError("Password dan Confirm Password Tidak Sesuai");
        }
      }
    })
  }

}
