import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { RoleMenus } from "../../utilities/role-menus";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivateChild {
  constructor(
    public auth: AuthService,
    public router: Router,
    public roleMenus: RoleMenus
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.auth.isAuthenticated()) {
      const userRole: any = this.auth.profileUser().roles[0];
      const userType: any = userRole.substr(0, 3);

      const userMenusUrlOnly: string[] = [];

      const userMenus: any[] = [
        ...this.roleMenus
          .getRoleMenus(userRole, userType)
          .filter((it: any) => it.url),
        ...this.roleMenus.getUserProfileMenus(),
      ];

      userMenus.forEach((it: any) => {
        if (it.children) {
          userMenusUrlOnly.push(
            ...it.children.map((itChildren: any) => {
              if (itChildren.url.charAt(0) === "/") {
                return itChildren.url.substring(1);
              }

              return itChildren.url;
            })
          );
        } else {
          if (it.url.charAt(0) === "/") {
            userMenusUrlOnly.push(it.url.substring(1));
          } else {
            userMenusUrlOnly.push(it.url);
          }
        }
      });

      if (route.children.length < 1) {
        let pathFromRoot = route.pathFromRoot
          .filter((it: any) => Object.keys(it.params).length < 1)
          .map((it: any) => it.routeConfig?.path)
          .filter((it: string) => it !== "")
          .join("/");

        if (pathFromRoot.charAt(0) === "/") {
          pathFromRoot = pathFromRoot.substring(1);
        }

        if (
          userMenusUrlOnly.some((it: string) => pathFromRoot.indexOf(it) > -1)
        ) {
          return true;
        }

        this.router.navigate(["404"]);
        return false;
      }

      sessionStorage.setItem("check", "yes");
      if (this.auth.isRegistered()) {
        this.router.navigate(["confirm-registration"]);
      } else {
        return true;
      }
    } else {
      this.router.navigate(["login"]);
      return false;
    }
  }
}
