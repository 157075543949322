import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "filter-bulan",
  templateUrl: "./filter-bulan.component.html",
  styleUrls: ["./filter-bulan.component.css"],
})
export class FilterBulanComponent implements OnInit {
  private _data;
  private _selectedYear;
  @Input() set data(value: any) {
    this._data = value;

    if (this._selectedYear) {
      this.mappingMonth();
    }
  }
  @Input() set selectedYear(value: any) {
    this._selectedYear = value;
    this.mappingMonth();
  }
  get data(): any {
    return this._data;
  }
  get selectedYear(): any {
    return this._selectedYear;
  }
  @Output() onChange = new EventEmitter<any>();
  @Input() selectedMonth;
  @Input() disabled;
  listMonth;
  months = [
    {
      value: "1",
      name: "Januari",
    },
    {
      value: "2",
      name: "Februari",
    },
    {
      value: "3",
      name: "Maret",
    },
    {
      value: "4",
      name: "April",
    },
    {
      value: "5",
      name: "Mei",
    },
    {
      value: "6",
      name: "Juni",
    },
    {
      value: "7",
      name: "Juli",
    },
    {
      value: "8",
      name: "Agustus",
    },
    {
      value: "9",
      name: "September",
    },
    {
      value: "10",
      name: "Oktober",
    },
    {
      value: "11",
      name: "November",
    },
    {
      value: "12",
      name: "Desember",
    },
  ];

  constructor() {}

  ngOnInit() {}

  mappingMonth() {
    if (this._data != undefined) {
      if (this._selectedYear != undefined) {
        const array = this._data.filter((x) => {
          return x.tahun == this._selectedYear;
        });

        if (array.length > 0) {
          const listMonth = array[0].bulan.map((x) => {
            return this.months.find((y) => {
              return x == y.value;
            });
          });

          const sortedMonths: any[] = [...listMonth].sort(
            (a: any, b: any) => Number(a.value) - Number(b.value)
          );

          this.listMonth = [...sortedMonths];
        }
      }
    }
  }

  reset() {
    this.listMonth = [];
  }

  handleOnChange(ev) {
    const value: any = ev[0].value;

    if (this.selectedMonth !== value) {
      this.onChange.emit(value);
    }
  }
}
