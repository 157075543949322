export const environment = {
  production: true,
  server: "https://api.ekososbud.dev.layanan.go.id",
  client: "http://localhost:4200",
  authUrlPolpum:
    "https://minisso.dev.layanan.go.id/rs/auth/web/check-login?redirect-url=http%3A%2F%2Flocalhost:4200%2Fauthenticate-polpum",
  polpumCheck:
    "https://minisso.dev.layanan.go.id/rs/auth/web/check-login?redirect-url=http%3A%2F%2Flocalhost:4200%2Fcheck-polpum",
  authUrlKominfo:
    "https://sso-dev.layanan.go.id/auth/realms/SPBE/protocol/openid-connect/auth?client_id=ekososbud&state=&redirect_uri=https%3A%2F%2Fekososbud.layanan.go.id%2Fauthenticate-kominfo%3Freturn%3D&response_type=code",
  logoutUrl: "https://minisso.dev.layanan.go.id/rs/auth/logout",
  recaptchaSiteKey: "6LeynRMmAAAAALNEGUxc5uP4_mNALZULSd4KWfYD",
};

export const environment_account = {
  production: false,
  server: "https://accountsvc.ekososbud.dev.layanan.go.id",
};

export const environment_user = {
  production: false,
  server: "https://usersvc.ekososbud.dev.layanan.go.id",
};

export const environment_file = {
  production: false,
  server: "https://filesvc.ekososbud.dev.layanan.go.id",
};

export const environment_dashboard = {
  production: false,
  server: "https://dashboardsvc.ekososbud.dev.layanan.go.id",
};

export const environment_document = {
  production: false,
  server: "https://dokservice.ekososbud.dev.layanan.go.id",
};
