import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import {
  LocationStrategy,
  HashLocationStrategy,
  registerLocaleData,
  PathLocationStrategy,
} from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import localeId from "@angular/common/locales/id";
registerLocaleData(localeId, "id");

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from "./app.component";

// Service
import { HttpErrorHandlerService } from "./handler/http-error-handler.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";

// Import containers
import { DefaultLayoutComponent } from "./containers";
import { LoginComponent } from "../app/views/login/login.component";
import { RegisterComponent } from "../app/views/register/register.component";

import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSelectModule } from "ngx-select-ex";
import { SweetAlert } from "./utilities/sweetalert";
import { RoleMenus } from "./utilities/role-menus";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxLoadingModule } from "ngx-loading";
import { OAuthModule } from "angular-oauth2-oidc";
import { JwtModule } from "@auth0/angular-jwt";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { RecaptchaModule } from "ng-recaptcha";

const APP_CONTAINERS = [DefaultLayoutComponent];

export function tokenGetter() {
  return localStorage.getItem("token");
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { LineChartPageComponent } from "./iframe/line-chart-page/line-chart-page.component";
import { StackedChartPageComponent } from "./iframe/stacked-chart-page/stacked-chart-page.component";
import { IframeLineChartPageModule } from "./iframe/line-chart-page/line-chart-page.module";
import { IframeStackedChartPageModule } from "./iframe/stacked-chart-page/stacked-chart-page.module";
import { PrivacyPolicyComponent } from "./views/privacy-policy/privacy-policy.component";
import { TermConditionComponent } from "./views/term-condition/term-condition.component";

@NgModule({
  imports: [
    BrowserModule,
    OAuthModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [],
        blacklistedRoutes: [],
      },
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    HttpClientModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    NgbModule,
    AppSidebarModule,
    NgxSelectModule,
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    SweetAlert2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxLoadingModule.forRoot({}),
    TooltipModule.forRoot(),
    LeafletModule,
    IframeLineChartPageModule,
    NgxMaskModule.forRoot(),
    IframeStackedChartPageModule,
    NgxChartsModule,
    RecaptchaModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    PrivacyPolicyComponent,
    TermConditionComponent,
    ...APP_CONTAINERS,
  ],
  entryComponents: [LineChartPageComponent, StackedChartPageComponent],
  providers: [
    NgbActiveModal,
    SweetAlert,
    RoleMenus,
    HttpErrorHandlerService,
    DefaultLayoutComponent,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "id-ID" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
