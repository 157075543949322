interface NavAttributes {
  [propName: string]: any;
}

interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}

interface NavBadge {
  text: string;
  variant: string;
}

interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export class NavMenu {
  title_permasalahan: NavData = {
    title: true,
    name: "Permasalahan KUB/PK",
  };

  title_pelaksanaan: NavData = {
    title: true,
    name: "Pelaksanaan P4GN & PN",
  };

  title_admin: NavData = {
    title: true,
    name: "Administrasi",
  };

  dashboard: NavData = {
    name: "Dashboard",
    url: "/permasalahan/dashboard",
    icon: "icon-speedometer",
  };

  dashboard_pelaksanaan: NavData = {
    name: "Dashboard",
    url: "/pelaksanaan/dashboard",
    icon: "icon-speedometer",
  };

  dashboard_admin: NavData = {
    name: "Dashboard",
    url: "/admin/dashboard",
    icon: "icon-speedometer",
  };

  // entry_permasalahan: NavData = {
  //   name: 'Input Permasalahan',
  //   url: '/permasalahan/entry-permasalahan',
  //   icon: 'icon-doc'
  // }

  entry_permasalahan: NavData = {
    name: "Input Permasalahan",
    url: "/permasalahan/daftar-permasalahan",
    icon: "icon-doc",
  };

  penelitian_permasalahan: NavData = {
    name: "Penelitian, Klarifikasi & Identifikasi",
    url: "/permasalahan/penelitian-klarifikasi-identifikasi",
    icon: "icon-magnifier",
  };

  verifikasi_permasalahan: NavData = {
    name: "Verifikasi & Koordinasi",
    url: "/permasalahan/verifikasi-koordinasi",
    icon: "icon-magnifier-add",
  };

  penanganan_permasalahan: NavData = {
    name: "Tindak Lanjut Permasalahan",
    url: "/permasalahan/penanganan-masalah",
    icon: "icon-question",
  };

  // laporan_permasalahan: NavData = {
  //   name: "Laporan Hasil Penanganan Masalah",
  //   url: "/permasalahan/laporan-hasil",
  //   icon: "icon-book-open",
  // };

  selesai_permasalahan: NavData = {
    name: "Permasalahan Selesai",
    url: "/permasalahan/permasalahan-selesai",
    icon: "icon-check",
  };

  user_management: NavData = {
    name: "User Management",
    url: "/admin/user-management",
    icon: "icon-people",
  };

  daftar_permasalahan_kabkot: NavData = {
    name: "Daftar Permasalahan KUB/PK Per Kab/Kota",
    url: "/permasalahan/daftar-permasalahan-kabkot",
    icon: "icon-list",
  };

  document_sk: NavData = {
    name: "Dokumen SK",
    url: "/permasalahan/document-sk",
    icon: "icon-docs",
  };

  monitoring_document_sk: NavData = {
    name: "Dokumen SK",
    url: "/permasalahan/monitoring/document-sk",
    icon: "icon-docs",
  };

  anggaran: NavData = {
    name: "Anggaran FKUB",
    url: "/permasalahan/anggaran",
    icon: "icon-docs",
  };

  daftar_dokumen_sk_provinsi: NavData = {
    name: "Daftar Dokumen SK Provinsi",
    url: "permasalahan/daftar-dokumen-sk-provinsi",
    icon: "icon-list",
  };

  daftar_dokumen_sk_kabkot: NavData = {
    name: "Daftar Dokumen SK Kab/Kot",
    url: "permasalahan/daftar-dokumen-sk-kabkot",
    icon: "icon-list",
  };

  daftar_anggaran_provinsi: NavData = {
    name: "Daftar Anggaran Provinsi",
    url: "permasalahan/daftar-anggaran-provinsi",
    icon: "icon-list",
  };

  daftar_anggaran_kabkot: NavData = {
    name: "Daftar Anggaran Kab/Kot",
    url: "permasalahan/daftar-anggaran-kabkot",
    icon: "icon-list",
  };

  daftar_pelaksanaan: NavData = {
    name: "Pelaksanaan",
    url: "pelaksanaan/daftar",
    icon: "icon-event",
    children: [
      {
        name: "Daftar TIMDU, RAD, PERDA",
        url: "pelaksanaan/daftar/timdu-rad-perda",
      },
      {
        name: "Daftar Laporan Pelaksanaan RAD",
        url: "pelaksanaan/daftar/laporan-rad",
      },
    ],
  };

  daftar_pelaksanaan_kabkot: NavData = {
    name: "Pelaksanaan",
    url: "pelaksanaan/daftar",
    icon: "icon-event",
    children: [
      {
        name: "Daftar TIMDU, RAD, PERDA",
        url: "pelaksanaan/daftar/timdu-rad-perda",
      },
      {
        name: "Daftar TIMDU, RAD, Kecamatan",
        url: "pelaksanaan/daftar/timdu-rad-kecamatan",
      },
      {
        name: "Daftar Laporan Pelaksanaan RAD",
        url: "pelaksanaan/daftar/laporan-rad",
      },
    ],
  };

  monitoring_pelaksanaan_kabkot: NavData = {
    name: "Monitoring",
    url: "pelaksanaan/monitoring",
    icon: "icon-screen-desktop",
    children: [
      {
        name: "Monitoring TIMDU, RAD, Kecamatan",
        url: "pelaksanaan/monitoring/kecamatan",
      },
      {
        name: "Monitoring Laporan Pelaksanaan RAD Kecamatan",
        url: "pelaksanaan/monitoring/pelaksanaan-kecamatan",
      },
    ],
  };

  monitoring_pelaksanaan_pusat: NavData = {
    name: "Monitoring",
    url: "pelaksanaan/monitoring",
    icon: "icon-screen-desktop",
    children: [
      {
        name: "Monitoring Seluruh Wilayah",
        url: "pelaksanaan/monitoring/pusat",
      },
      {
        name: "Monitoring Pelaksanaan RAD",
        url: "pelaksanaan/monitoring/pelaksanaan-pusat",
      },
    ],
  };

  monitoring_pelaksanaan_provinsi: NavData = {
    name: "Monitoring",
    url: "pelaksanaan/monitoring",
    icon: "icon-screen-desktop",
    children: [
      {
        name: "TIMDU, RAD, PERDA Kab/Kota",
        url: "pelaksanaan/monitoring/kabkot",
      },
      {
        name: "TIMDU, RAD, Kecamatan",
        url: "pelaksanaan/monitoring/kecamatan",
      },
      {
        name: "Monitoring Laporan Pelaksanaan RAD Kab/Kota",
        url: "pelaksanaan/monitoring/pelaksanaan-kab-kota",
      },
      {
        name: "Monitoring Pelaksanaan RAD Kecamatan",
        url: "pelaksanaan/monitoring/pelaksanaan-kecamatan",
      },
    ],
  };

  verifikasi_pelaksanaan: NavData = {
    name: "Verifikasi",
    url: "pelaksanaan/verifikasi",
    icon: "icon-note",
    children: [
      {
        name: "TIMDU, RAD PERDA Kab/Kota",
        url: "pelaksanaan/verifikasi/kabkot",
      },
      {
        name: "TIMDU, RAD Kecamatan",
        url: "pelaksanaan/verifikasi/kecamatan",
      },
      {
        name: "Laporan Pelaksanaan RAD",
        url: "pelaksanaan/verifikasi/laporan",
      },
    ],
  };

  verifikasi_pelaksanaan_pusat: NavData = {
    name: "Verifikasi",
    url: "pelaksanaan/verifikasi",
    icon: "icon-note",
    children: [
      {
        name: "TIMDU, RAD PERDA Provinsi",
        url: "pelaksanaan/verifikasi/provinsi",
      },
      {
        name: "TIMDU, RAD PERDA Kab/Kota",
        url: "pelaksanaan/verifikasi/kabkot",
      },
      {
        name: "TIMDU, RAD, Kecamatan",
        url: "pelaksanaan/verifikasi/kecamatan",
      },
      {
        name: "Laporan Pelaksanaan RAD",
        url: "pelaksanaan/verifikasi/laporan",
      },
    ],
  };

  laporan_permasalahan_selesai: NavData = {
    name: "Laporan Permasalahan",
    url: "/permasalahan/laporan-permasalahan",
    icon: "icon-docs",
  };

  daftar_permasalahan_provinsi: NavData = {
    name: "Daftar Permasalahan KUB/PK Per Provinsi",
    url: "/permasalahan/daftar-permasalahan-provinsi",
    icon: "icon-list",
  };

  daftar_laporan_pembinaan: NavData = {
    name: "Daftar Pembinaan & Pemeliharaan FKUB",
    url: "permasalahan/laporan-pembinaan",
    icon: "icon-list",
  };

  baseMenu(): NavData[] {
    return [];
  }
}
