import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterBulanComponent } from './filter-bulan.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FilterBulanComponent
  ],
  imports: [
    CommonModule,
    NgxSelectModule,
    NgxLoadingModule,
    FontAwesomeModule,
    FormsModule
  ],
  entryComponents:[
    FilterBulanComponent
  ],
  exports:[
    FilterBulanComponent
  ]
})
export class FilterBulanModule { }