import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReferencesService } from '../../services/references/references.service';

@Component({
  selector: 'filter-provinsi',
  templateUrl: './filter-provinsi.component.html',
  styleUrls: ['./filter-provinsi.component.css']
})
export class FilterProvinsiComponent implements OnInit {

  loading: Boolean
  private _data
  private _provinsi
  selected
 @Input() defaultValue

  @Output() onChange = new EventEmitter<any>();
  listProvince

  constructor(
    private service: ReferencesService
  ) { }

  ngOnInit() {
    this.getProvinces()
  }

  getProvinces() {
    this.service.getListProvince().subscribe(
      (resp) => {
        this.loading = false;
        this.listProvince = resp.listProvinsi;
        if(this.defaultValue != null){
          this.selected = (this.findSelected(this.listProvince)).kodeProvinsi
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  reset() {
    this.listProvince = []
    this.getProvinces()
  }

  findSelected(array) {
   return array.find(x => x.kodeProvinsi == this.defaultValue)
  }

}
