import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { environment } from "../environments/environment";
import { AuthService } from "./services/auth/auth.service";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: "<router-outlet></router-outlet>",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "SISTEM INFORMASI MANAJEMEN KETAHANAN EKONOMI SOSIAL DAN BUDAYA";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    (window as any).pdfWorkerSrc =
      "https://www.unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js";
  }
}
