import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StackedChartPageComponent } from './stacked-chart-page.component';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StackedBarChartComponent } from '../../shared/stacked-bar-chart/stacked-bar-chart.component';
import { StackedBarChartModule } from '../../shared/stacked-bar-chart/stacked-bar-chart.module';
import { FilterTahunComponent } from '../../shared/filter-tahun/filter-tahun.component';
import { FilterTahunModule } from '../../shared/filter-tahun/filter-tahun.module';
import { FilterProvinsiComponent } from '../../shared/filter-provinsi/filter-provinsi.component';
import { FilterProvinsiModule } from '../../shared/filter-provinsi/filter-provinsi.module';
import { FilterBulanComponent } from '../../shared/filter-bulan/filter-bulan.component';
import { FilterBulanModule } from '../../shared/filter-bulan/filter-bulan.module';

@NgModule({
    imports: [
        CommonModule,
        NgxSelectModule,
        NgxLoadingModule,
        FontAwesomeModule,
        FormsModule,
        NgxChartsModule,
        StackedBarChartModule,
        FilterTahunModule,
        FilterProvinsiModule,
        FilterBulanModule
    ],
    declarations: [
        StackedChartPageComponent,
    ],
    entryComponents: [
        StackedChartPageComponent,
        StackedBarChartComponent,
        FilterTahunComponent,
        FilterProvinsiComponent,
        FilterBulanComponent
    ],
    exports: [
        StackedChartPageComponent
    ]
})
export class IframeStackedChartPageModule { }