import { DataResponse } from "./../../models/response/DataResponse";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LoginModel } from "../../models/response/Login";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  environment,
  environment_account,
} from "../../../environments/environment";
import {
  HandleError,
  HttpErrorHandlerService,
} from "../../handler/http-error-handler.service";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private prefixUrl = environment_account.server;
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    this.handleError = httpErrorHandler.createHandleError("AuthService");
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    // const token = this.oauthService.getAccessToken();

    // debugger
    if (!token) {
      return false;
    }

    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
    return true;
  }

  public logoutPolpum(): Observable<Object> {
    return this.http.get(environment.logoutUrl);
  }

  public isRegistered(): boolean {
    const profile = localStorage.getItem("profile");
    const registered = JSON.parse(profile)?.registered;
    // const token = this.oauthService.getAccessToken();

    if (registered) {
      return false;
    }

    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
    return true;
  }

  loginPolpum(token: string): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/account/login-polpum",
      {
        token,
      },
      httpOptions
    );
  }

  loginKominfo(code: string): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/account/login-kominfo",
      {
        code,
      },
      httpOptions
    );
  }

  checkLogin(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/account/check",
      httpOptions
    );
  }

  login(dataRequest: LoginModel): Observable<DataResponse> {
    const observable = new Observable<DataResponse>((observer) => {
      this.http
        .post<DataResponse>(
          this.prefixUrl + "/account/login-token",
          dataRequest,
          httpOptions
        )
        .subscribe(
          (resp) => {
            this.setSession(resp);
            observer.next(resp);
          },
          (error) => {
            // debugger
            /*const response: DataResponse = {
              success: false,
              message: error.error,
              data: error
            };*/
            observer.error(error);
          }
        );
    });
    return observable;
  }

  public setSession(authResult) {
    // const expiresAt = moment().add(authResult.expired,'second');
    localStorage.setItem("profile", JSON.stringify(authResult.profile));
    localStorage.setItem("token", authResult.token);
    localStorage.setItem("type", "internal");
    // localStorage.setItem("expires_at", authResult.exp);
  }

  public setPolpumSession(authResult) {
    localStorage.setItem("profile", JSON.stringify(authResult.profile));
    localStorage.setItem("token", authResult.token);
    localStorage.setItem("type", "polpum");
  }

  eraseSessionData() {
    localStorage.removeItem("profile");
    localStorage.removeItem("token");
  }

  profileUser() {
    const profile = localStorage.getItem("profile");
    return JSON.parse(profile);
  }
}
