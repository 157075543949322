import { Injectable } from "@angular/core";
import { NavMenu } from "../_nav";

@Injectable()
export class RoleMenus {
  private navMenu: NavMenu = new NavMenu();

  constructor() {}

  getSAMenus() {
    return [
      this.navMenu.title_admin,
      this.navMenu.dashboard_admin,
      this.navMenu.user_management,
    ];
  }

  getFKUBPusatMenus() {
    return [
      this.navMenu.title_permasalahan,
      this.navMenu.dashboard,
      this.navMenu.daftar_permasalahan_provinsi,
      this.navMenu.daftar_permasalahan_kabkot,
      this.navMenu.daftar_dokumen_sk_provinsi,
      this.navMenu.daftar_dokumen_sk_kabkot,
      this.navMenu.daftar_anggaran_provinsi,
      this.navMenu.daftar_anggaran_kabkot,
      this.navMenu.daftar_laporan_pembinaan,
    ];
  }

  getFKUBProvMenus() {
    return [
      this.navMenu.title_permasalahan,
      this.navMenu.dashboard,
      this.navMenu.entry_permasalahan,
      this.navMenu.penelitian_permasalahan,
      this.navMenu.verifikasi_permasalahan,
      this.navMenu.penanganan_permasalahan,
      this.navMenu.selesai_permasalahan,
      this.navMenu.laporan_permasalahan_selesai,
      this.navMenu.daftar_permasalahan_kabkot,
      this.navMenu.document_sk,
      this.navMenu.anggaran,
      this.navMenu.daftar_laporan_pembinaan,
    ];
  }

  getFKUBKabMenus() {
    return [
      this.navMenu.title_permasalahan,
      this.navMenu.dashboard,
      this.navMenu.entry_permasalahan,
      this.navMenu.penelitian_permasalahan,
      this.navMenu.verifikasi_permasalahan,
      this.navMenu.penanganan_permasalahan,
      this.navMenu.selesai_permasalahan,
      this.navMenu.laporan_permasalahan_selesai,
      this.navMenu.document_sk,
      this.navMenu.anggaran,
      this.navMenu.daftar_laporan_pembinaan,
    ];
  }

  getKSBPusatMenus() {
    return [
      this.navMenu.title_pelaksanaan,
      this.navMenu.dashboard_pelaksanaan,
      this.navMenu.verifikasi_pelaksanaan_pusat,
      this.navMenu.monitoring_pelaksanaan_pusat,
    ];
  }

  getKSBProvMenus() {
    return [
      this.navMenu.title_pelaksanaan,
      this.navMenu.dashboard_pelaksanaan,
      this.navMenu.daftar_pelaksanaan,
      this.navMenu.verifikasi_pelaksanaan,
      this.navMenu.monitoring_pelaksanaan_provinsi,
    ];
  }

  getKSBKabMenus() {
    return [
      this.navMenu.title_pelaksanaan,
      this.navMenu.dashboard_pelaksanaan,
      this.navMenu.daftar_pelaksanaan_kabkot,
      this.navMenu.monitoring_pelaksanaan_kabkot,
    ];
  }

  public getUserProfileMenus() {
    return [
      {
        url: "admin/edit-profile",
      },
      {
        url: "admin/ganti-password",
      },
    ];
  }

  public getRoleMenus(role: string, type: string = null) {
    if (role === "SA") {
      return this.getSAMenus();
    } else if (type === "FKU" && role === "FKUB_PUSAT") {
      return this.getFKUBPusatMenus();
    } else if (type === "FKU" && role === "FKUB_PROV") {
      return this.getFKUBProvMenus();
    } else if (type === "FKU" && role === "FKUB_KABKOT") {
      return this.getFKUBKabMenus();
    } else if (type === "KSB" && role === "KSB_PUSAT") {
      return this.getKSBPusatMenus();
    } else if (type === "KSB" && role === "KSB_PROV") {
      return this.getKSBProvMenus();
    }

    return this.getKSBKabMenus();
  }
}
