import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthParentGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let check = sessionStorage.getItem("check");
    let type = localStorage.getItem("type");
    if (
      type == "polpum" &&
      !state.url.includes("check-login") &&
      check == "yes"
    ) {
      var encoded = encodeURI("?next=" + state.url);
      location.href = environment.polpumCheck + encoded;
    } else {
      if (this.auth.isAuthenticated()) {
        sessionStorage.setItem("check", "yes");
        if (this.auth.isRegistered()) {
          this.router.navigate(["confirm-registration"]);
        } else {
          return true;
        }
      } else {
        this.router.navigate(["login"]);
        return false;
      }
    }
  }
}
