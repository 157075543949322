import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { environment, environment_file } from "../../../environments/environment";
import { DataResponse } from "../../models/response/DataResponse";
import {
  HandleError,
  HttpErrorHandlerService,
} from "../../handler/http-error-handler.service";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
const httpOptionsToken = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }),
};
@Injectable({
  providedIn: "root",
})
export class ReferencesService {
  private prefixUrl = environment.server;
  private prefixUrlFile = environment_file.server;
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService,
    public jwtHelper: JwtHelperService
  ) {
    this.handleError = httpErrorHandler.createHandleError("ReferencesService");
  }

  getListProvince(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/daerah/list-provinsi",
      httpOptionsToken
    );
  }

  getListKabKot(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/daerah/list-kabkot/" + dataRequest,
      httpOptionsToken
    );
  }

  uploadFile(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrlFile + "/file/upload",
      dataRequest
    );
  }

  previewFile(data): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + data,
      httpOptions
    );
  }

  getKecamatan(data): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/daerah/list-kecamatan/" + data,
      httpOptionsToken
    );
  }

}