export class ProfileModel {
    username?: string = '';
    password?: string = '';
    confirmPassword?: string = '';
    email?: string = '';
    role?: string = '';
    method?:string = '';
    provinsi?:string = '';
    kabkot?:string = '';
    fullname?:string = '';
    noKTP?:string = '';
    jabatan?:string = '';
    phoneNumber?:string = '';
    nip?:string = '';
    urlFoto?:string = '';
    registered?:boolean = false;

    static defaultData() {
        return {
            username: '',
            password: '',
            confirmPassword: '',
            email: '',
            role: '',
            method: '',
            provinsi: '',
            kabkot: '',
            fullname: '',
            noKTP: '',
            jabatan: '',
            phoneNumber: '',
            nip: '',
            urlFoto: '',
            registered: false,
        };
    }
}
