import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SweetAlert } from '../utilities/sweetalert';
import { Router, ActivatedRoute} from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private alert: SweetAlert,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');
    if (token) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${token}`
            }
        });
    }

    return next.handle(request);
    
  }

}
