import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class SweetAlert {
  constructor() {}

  showError(messages) {
    Swal.fire({
      text: messages,
      type: 'error'
    });
  }

  showSuccess(messages) {
    return Swal.fire({
      text: messages,
      type: 'success'
    });
  }

  showSuccessAuto(messages) {
    Swal.fire({
      text: messages,
      type: 'success',
      timer: 1000,
      showConfirmButton: false
    });
  }

  showWarning(messages) {
    Swal.fire({
      text: messages,
      type: 'warning'
    });
  }

  showInfo(html) {
    Swal.fire({
      html: html,
      type: 'info',
      showCancelButton: true,
      showConfirmButton: false
    });
  }

  showConfirm(title, messages) {
    return Swal.fire({
      title: title,
      text: messages,
      type: 'info',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    });
  }
  showHTML(title,html, preConfirm) {
    return Swal.fire({
      title: title,
      type: 'info',
      html: html,
      preConfirm: preConfirm,
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    });
  }
  showInfoResetPassword(title,html) {
    return Swal.fire({
      title: title,
      type: 'info',
      html: html,
      showCancelButton: false,
      confirmButtonText: 'OK',
      confirmButtonColor: '#3085d6'
    });
  }

}
