import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import {
  environment,
  environment_dashboard,
} from "../../../environments/environment";
import { DataResponse } from "../../models/response/DataResponse";
import {
  HandleError,
  HttpErrorHandlerService,
} from "../../handler/http-error-handler.service";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};
@Injectable({
  providedIn: "root",
})
export class PermasalahanService {
  private prefixUrl = environment.server;
  private prefixUrlDashboard = environment_dashboard.server;
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService,
    public jwtHelper: JwtHelperService
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      "PermasalahanService"
    );
  }

  getTipePermasalahan(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/tipe-permasalahan",
      httpOptions
    );
  }

  getJenisPermasalahan(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/jenis-permasalahan/" + dataRequest,
      httpOptions
    );
  }

  createLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/create-laporan-permasalahan",
      dataRequest,
      httpOptions
    );
  }

  deletelaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/delete-laporan",
      dataRequest,
      httpOptions
    );
  }

  getDetail(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/detail-laporan/" + dataRequest,
      httpOptions
    );
  }

  updateLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/update-laporan-permasalahan/",
      dataRequest,
      httpOptions
    );
  }

  getDataBarChartPusat(
    tahun,
    bulan,
    tipe,
    chartType
  ): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrlDashboard}/dashboardPermasalahan/stacked-chart-permasalahan`,
      {
        ...httpOptions,
        params: {
          tahun,
          bulan,
          tipe,
          tipeChart: chartType,
        },
      }
    );
  }

  getDataBarChartNonPusat(
    kodeProv,
    tahun,
    bulan,
    type = null,
    chartType
  ): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrlDashboard}/dashboardPermasalahan/stacked-chart-permasalahan`,
      {
        ...httpOptions,
        params: {
          kodeProv,
          tahun,
          bulan,
          ...(type !== null && {
            tipe: type,
          }),
          tipeChart: chartType,
        },
      }
    );
  }

  getMapProvince(type, tahun): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPermasalahan/map-province-permasalahan?tipe=" +
        type +
        "&tahun=" +
        tahun,
      httpOptions
    );
  }

  getMapDistrict(kdProvince, type, year?): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPermasalahan/map-district-permasalahan",
      {
        ...httpOptions,
        params: {
          kodeProvinsi: kdProvince,
          tipe: type,
          ...(year && {
            tahun: year,
          }),
        },
      }
    );
  }

  getLineChart(tahun: any, chartType?: any): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPermasalahan/line-chart-permasalahan",
      {
        ...httpOptions,
        params: {
          tahun,
          ...(chartType && {
            tipeChart: chartType,
          }),
        },
      }
    );
  }

  export(type): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl +
        "/laporanpermasalahan/export-datatable-monitoring/" +
        type,
      httpOptions
    );
  }

  createSuratKeterangan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/suratketerangan/create",
      dataRequest,
      httpOptions
    );
  }

  deleteSuratKeterangan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/suratketerangan/delete",
      dataRequest,
      httpOptions
    );
  }

  createAnggaran(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/anggaranfkub/create",
      dataRequest,
      httpOptions
    );
  }

  deleteAnggaran(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/anggaranfkub/delete",
      dataRequest,
      httpOptions
    );
  }

  exportMonitoring(tipe, dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl +
        "/laporanpermasalahan/export-datatable-monitoring/" +
        tipe,
      dataRequest,
      httpOptions
    );
  }

  exportLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/export-data-table/SELESAI",
      dataRequest,
      httpOptions
    );
  }

  approveLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/approve-laporan",
      dataRequest,
      httpOptions
    );
  }

  rejectLaporan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpermasalahan/reject-laporan",
      dataRequest,
      httpOptions
    );
  }

  createPembinaan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpembinaan/create-laporan-permbinaan",
      dataRequest,
      httpOptions
    );
  }

  updpatePembinaan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpembinaan/update-laporan-pembinaan",
      dataRequest,
      httpOptions
    );
  }

  getPembinaanById(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      this.prefixUrl + "/laporanpembinaan/detail-laporan/" + dataRequest,
      httpOptions
    );
  }

  deletePembinaan(dataRequest): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpembinaan/delete-laporan",
      dataRequest,
      httpOptions
    );
  }

  countMenuNotification(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/laporanpermasalahan/data-notification`,
      httpOptions
    );
  }

  countMenuPembinaan(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/laporanpembinaan/count-laporan-pembinaan`,
      httpOptions
    );
  }

  countMenuDokumenSK(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/suratketerangan/count-surat-keterangan`,
      httpOptions
    );
  }

  countMenuAnggaran(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/anggaranfkub/count-anggaran`,
      httpOptions
    );
  }

  readMenuNotification(): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/laporanpermasalahan/read`,
      httpOptions
    );
  }

  getAnggaranProvinsiBarChart(
    params: Record<any, any>
  ): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/anggaranfkub/dashboard-anggaran-provinsi`,
      { ...httpOptions, params }
    );
  }

  getAnggaranKabkotBarChart(
    params: Record<any, any>
  ): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/anggaranfkub/dashboard-anggaran-kabkot`,
      { ...httpOptions, params }
    );
  }

  getExportPDF(dataRequest): Observable<DataResponse> {
    return this.http.get<DataResponse>(
      `${this.prefixUrl}/laporanpermasalahan/export-pdf-laporan/${dataRequest}`,
      httpOptions
    );
  }

  doReadPembinaan(dataRequest: Record<string, any>): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      `${this.prefixUrl}/laporanpembinaan/read-laporan-pembinaan/`,
      dataRequest,
      httpOptions
    );
  }

  getExportBundlePembinaan(id: string): Observable<any> {
    return this.http.post<any>(
      `${this.prefixUrl}/laporanpembinaan/export-pdf-laporan/${id}`,
      {},
      httpOptions
    );
  }

  getExportPembinaan(dataRequest, isPdf?: boolean): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrl + "/laporanpembinaan/export-pdf-pembinaan",
        dataRequest,
        httpOptions
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrl + "/laporanpembinaan/export-excel-pembinaan",
      dataRequest,
      httpOptions
    );
  }

  doReadDokumenSK(dataRequest: Record<string, any>): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      `${this.prefixUrl}/suratketerangan/read-surat-keterangan/`,
      dataRequest,
      httpOptions
    );
  }

  doReadAnggaran(dataRequest: Record<string, any>): Observable<DataResponse> {
    return this.http.post<DataResponse>(
      `${this.prefixUrl}/anggaranfkub/read-anggaran/`,
      dataRequest,
      httpOptions
    );
  }

  getExportDashboardMap(
    dataRequest: Record<string, any>,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrlDashboard +
          "/dashboardPermasalahan/pdf-rakaptulasi-laporan",
        {},
        { ...httpOptions, params: dataRequest }
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPermasalahan/excel-rakaptulasi-laporan",
      {},
      { ...httpOptions, params: dataRequest }
    );
  }

  getExportDashboardMapProvince(
    dataRequest: Record<string, any>,
    isPdf?: boolean
  ): Observable<DataResponse> {
    if (isPdf) {
      return this.http.post<DataResponse>(
        this.prefixUrlDashboard +
          "/dashboardPermasalahan/pdf-rakaptulasi-laporan-provinsi",
        {},
        { ...httpOptions, params: dataRequest }
      );
    }

    return this.http.post<DataResponse>(
      this.prefixUrlDashboard +
        "/dashboardPermasalahan/excel-rakaptulasi-laporan-provinsi",
      {},
      { ...httpOptions, params: dataRequest }
    );
  }
}
