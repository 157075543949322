import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "filter-tahun",
  templateUrl: "./filter-tahun.component.html",
  styleUrls: ["./filter-tahun.component.css"],
})
export class FilterTahunComponent implements OnInit {
  private _data;
  selectedYear: any = null;
  defaultValue: any = null;

  @Input() set data(value: any) {
    this._data = value;
    this.mapping();
  }

  @Input() set defaultYear(value: string) {
    this.defaultValue = value;
  }

  get data(): any {
    return this._data;
  }
  @Output() onChange = new EventEmitter<any>();
  listYear;
  @Input() disabled;

  constructor() {}

  ngOnInit() {}

  reset(value: any) {
    this._data = value;
    this.listYear = [];
    this.selectedYear = null;
    this.mapping();
  }

  mapping() {
    if (this.data !== undefined) {
      const tempYears: any[] = [...this.data].map((x) => ({
        id: x.tahun,
        label: x.tahun,
        bulan: x.bulan,
      }));

      const sortedYears: any[] = [...tempYears].sort(
        (a: any, b: any) => Number(b.label) - Number(a.label)
      );

      this.listYear = [...sortedYears];
    }
  }

  selected(event) {
    this.onChange.emit(event);
    this.selectedYear = event;

    return event;
  }
}
