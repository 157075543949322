import { environment } from './../../../environments/environment';
import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: "app-authenticate-polpum",
	templateUrl: "./authenticate.component.html"
})
export class AuthenticatePolpumComponent implements OnInit {
	constructor(private route: ActivatedRoute, private authService: AuthService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params.at != null) {
				const at = JSON.parse(params.at);
				this.authService.loginPolpum(at.access_token)
					.subscribe(resp => {
						this.authService.setPolpumSession(resp)
						this.router.navigateByUrl("/");
					}, err => {
						this.router.navigate(['login']);
					});
			}
		})
	}
}

@Component({
	selector: "app-authenticate-kominfo",
	templateUrl: "./authenticate.component.html"
})
export class AuthenticateKominfoComponent implements OnInit {
	constructor(private route: ActivatedRoute, private authService: AuthService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params.code != null) {
				this.authService.loginKominfo(params.code)
					.subscribe(resp => {
						this.authService.setSession(resp)
						this.router.navigateByUrl("/");
					})
			}
		}, err => {
			this.router.navigate(['login']);
		})
		return
	}
}

@Component({
	selector: "app-check-polpum",
	templateUrl: "./authenticate.component.html"
})
export class CheckPolpumComponent implements OnInit {
	constructor(private route: ActivatedRoute, private authService: AuthService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			sessionStorage.setItem('check', 'no');
			if (params.next != null) {
				this.router.navigateByUrl(params.next);
			} else {
				this.router.navigateByUrl("/")
			}
		})
	}
}