import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StackedBarChartComponent } from './stacked-bar-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    StackedBarChartComponent
  ],
  imports: [
    CommonModule,
    NgxLoadingModule,
    FontAwesomeModule,
    NgxChartsModule
  ],
  entryComponents:[
    StackedBarChartComponent
  ],
  exports:[
    StackedBarChartComponent
  ]
})
export class StackedBarChartModule { }